<template>
  <iframe :src="url" width="100%" height="100%" frameborder="0" scrolling="auto"></iframe>
</template>
<script>
export default {
  name: 'UpgradeLog',
  data() {
    return {
      url: 'http://iotp.fast-fun.cn/#/upgradeTaskDetail'
    }
  },
  mounted() {

  }
}
</script>